import { render, staticRenderFns } from "./MainAppLayout.vue?vue&type=template&id=56a40b9d&scoped=true&"
import script from "./MainAppLayout.vue?vue&type=script&lang=ts&"
export * from "./MainAppLayout.vue?vue&type=script&lang=ts&"
import style0 from "./MainAppLayout.vue?vue&type=style&index=0&id=56a40b9d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_vue-template-compiler@2.6.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56a40b9d",
  null
  
)

export default component.exports