

















import { computed, defineComponent } from "@vue/composition-api";
import accountStateRef, { isNasabah, isPengelola } from "@/apps/accounts/modules/store";

export default defineComponent({
  name: "MainAppLayout",
  components: {
    BottomNavbar: () => import("../components/BottomNavbar.vue"),
    SideBar: () => import("../components/SideBar.vue"),
  },
  setup() {
    return {
      userId: computed(() => accountStateRef.me.id),
      isNasabah,
      isPengelola
    };
  },
});
